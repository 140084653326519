/**
 * vendor.js
 *
 * Por questões de organização dos templates, bootstrap não é carregado
 * neste vendor. Bootstrap é de responsabilidade do próprio template, e deve
 * ser incluído nos vendors do template.
 */

// ===== jQuery
try {
    global.$ = global.jQuery = global.jquery = require('jquery');
    window.Popper = require('popper.js').default;
} catch (e){}

// ===== axios
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = $('meta[name="csrf-token"]').attr('content');

// ===== lodash
window._ = require("lodash");

// ===== moment
import moment from 'moment/moment';
import 'moment/locale/pt-br.js';
window.moment = moment;

// ===== sweetalert: modal de alerta
window.swal = require('sweetalert');

// ===== datatables
require('./vendor/datatable.js');

// ===== jquery-mask: mascaras dos input
require('jquery-mask-plugin');

// ===== daterangepicker: calendário no input
require('daterangepicker');

// ===== awesomplete: autocomplete de sugestão
require('awesomplete');

// ===== crypto-js: usado no chat, funcionalidade de criptografar com par de chaves publica e privada
window.CryptoJS = require("crypto-js");

// ===== echo
window.io = require('socket.io-client');
import Echo from 'laravel-echo';

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.location.hostname + ':' + window.location.port
});
